form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    height: calc(100vh - 110px);
    overflow: auto;
  }

  
  form div {
    margin-bottom: 15px;
  }
  .ant-picker-input {
    margin-bottom: 0px;
  }
  .cancel {
    padding: 19px;
    margin-right: 20px;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="number"],
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  input[type="datetime-local"] {
    display: block;
    width: calc(100% - 10px);
    float: left;
  }
  
  input[type="datetime-local"] + label {
    float: left;
    margin: 0 10px;
  }
  
  form:after {
    content: "";
    display: table;
    clear: both;
  }
  