.messages {
    height: calc(100vh - 105px);
}

.footer {
    border-top: 1px solid #eee;
    box-sizing: border-box;
    padding-top: 20px;
    height: 100px;
    display: flex;
    align-items: center;
}

.main {
    height: calc(100vh - 205px);
    overflow: auto;
}

.comment {
    padding: 15px;
    background-color: #eeeeee;
    border-radius: 10px;
    margin-bottom: 5px;
}

.datetime {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    text-align: left;
    margin-bottom: 15px;
}

.disable {
    pointer-events: none;
    background-color: gray;
}

.steps {
    padding: 20px;
    width: 100%;
    background-color: #fff;
    overflow: auto;
}

.stepDiscription {
    padding: 20px;
    background-color: #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
}

.back {
    cursor: pointer;
    padding: 20px 20px 0;
    font-weight: 900;
}

.mySentMessage {
    float: right;
}


.receivedMessage {
    float: left;
}

.suggestionsDropdown {
    position: absolute;
    bottom: 100px; /* Adjust this value based on your layout */
    left: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    left: 40px;
}

.suggestionsDropdown .react-select__menu {
    position: static !important;
}


.messages {
    display: flex;
    flex-direction: column;
}

.message {
    display: flex;
    margin: 10px 0;
}

.message.sent {
    justify-content: flex-end;
}

.message.received {
    justify-content: flex-start;
}

.message-content {
    max-width: 60%;
    padding: 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.message.sent .message-content {
    background-color: #d1e7dd;
}

.message.received .message-content {
    background-color: #f8d7da;
}