.searchList {
    max-height: 300px;
    left: 20px;
    border: 1px solid #e1e3e1;
    width: 233px;
    background-color: #fff;
    position: absolute;
    overflow: auto;
}

.searchItem {
    padding: 12px 20px;
    border-bottom: 1px solid #e1e3e1;
}

.searchItem:hover {
    cursor: pointer;
    background-color: #e1e3e1;
}

.notificationsCount {
    background-color: red;
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    margin-left: 5px;
}
