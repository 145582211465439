body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-btn.css-dev-only-do-not-override-zg0ahe.ant-btn-primary {
  background-color: rgb(0, 62, 107);
}

@media print {
  html, body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .print-process {
    margin: 50px;
    display: block!important;
  }
}