.sidebar {
  width: 290px;
  height: calc(100vh - 60px);
  background-color: #000;
}

.timeentry {
  display: flex;
  flex-direction: column;
  min-height: 36px;
  background-color: #fff;
  border: 1px solid;
  position: relative;
}

.placeholderbg {
  box-sizing: border-box;
  border: 0.1px solid #6c6c6c;
  width: 60px;
}

.line {
  height: calc(99vh)!important;
  z-index: 99;
  width: 2px!important;
  overflow: visible;
  background: black;
  position: absolute;
}

.heading-type {
  width: calc(100vw - 470px);
  display: flex;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}


.list-header {
  padding: 15px;
  border-radius: 12px;
  text-align: center;
}

.status-list {
  display: flex;
  gap: 15px;
  width: calc((100vw - 470px)/4);
  flex-direction: column;
}
.title-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  margin-bottom: 50px;
}

.view {
  text-decoration: underline;
  cursor: pointer;
}

.table {
  margin: 20px;
}


.st-overdue {
  color: #ff7c7c;
}
.st-inprogress {
  color: #fbcf69;
}
.st-open {
  color: #a2a2a2;
}
.st-completed {
  color: #5afc5f;
}

.button-send-email {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}