/* General styles */
.p-4 {
    padding: 1rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.font-bold {
    font-weight: 700;
}

.mb-4 {
    margin-bottom: 1rem;
}

/* Table styles */
.w-full {
    width: 100%;
}

.border-collapse {
    border-collapse: collapse;
}

.border {
    border-width: 1px;
}

.border-gray-300 {
    border-color: #d1d5db;
}

/* Table header styles */
.bg-gray-100 {
    background-color: #f3f4f6;
}

/* Table cell styles */
.p-2 {
    padding: 0.5rem;
}

.text-left {
    text-align: left;
}

/* Flex styles for process name and icon */
.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

/* Icon styles */
.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

/* Background colors for Last Review column */
.bg-green-200 {
    background-color: #a7f3d0;
}

.bg-yellow-200 {
    background-color: #fde68a;
}

.button-send-message{
    float: right;
    margin-right: 30px;
    margin-bottom: 20px;
}