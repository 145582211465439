.comments {
    height: calc(100vh - 105px);
}

.footer {
    border-top: 1px solid #eee;
    box-sizing: border-box;
    padding-top: 20px;
    height: 100px;
    display: flex;
    align-items: center;
}

.main {
    height: calc(100vh - 205px);
    overflow: auto;
}

.comment {
    padding: 15px;
    background-color: #eeeeee;
    border-radius: 10px;
    margin-bottom: 5px;
}

.datetime {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    text-align: left;
    margin-bottom: 15px;
}

.disable {
    pointer-events: none;
    background-color: gray;
}

.steps {
    padding: 20px;
    width: 100%;
    background-color: #fff;
    overflow: auto;
}

.stepDiscription {
    padding: 20px;
    background-color: #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
}

.back {
    cursor: pointer;
    padding: 20px 20px 0;
    font-weight: 900;
}

/* Style.module.css */
.noChanges {
    font-style: italic;
    color: #888;
  }
  
  .processVersions {
    margin: 20px;
  }
  
  .versionDetails {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .deletedContent {
    text-decoration: line-through;
    color: red;
  }
  
  .addedContent {
    font-weight: bold;
    color: green;
  }
  
  .stepVersionDiff {
    margin-top: 10px;
  }
  
  .addedStep {
    margin-top: 10px;
    background-color: #e8f5e9;
    padding: 10px;
    border-left: 4px solid green;
  }
  